body {
    margin: 0
}

#provider-logo-dark {
    display: none !important
}

#provider-logo {
    display: block !important
}


/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: gray #ddd;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
    border: 3px solid #ddd;
  }